import React from 'react'
import HubspotForm from 'react-hubspot-form'
import Layout from '../../../components/layout'

import {
	Container,
	Section,
} from '../../../components/layouts'

import {
	Subtitle,
	Paragraph,
	BlogAuthor,
	Link,
} from '../../../components/ui'

import powerPageHeading from '../../../assets/images/powerpage_heading.png'

import theme from '../../../theme/theme'

const {
	intro,
} = theme.sections

class IndexPage extends React.Component {

	componentDidMount() {
		window.scrollTo(0, -1500)
	}

	render () {
		return (
			<Layout>
				<Section id="intro" bg={intro.bg} paddingTop={'100px'}>
					<Container  >
						<img alt="Turning Millennials into Donors" src={powerPageHeading} width="100%"/>

						<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }}>Actionable tips and methods for nonprofits looking to reach and engage millennials as active donors </Subtitle><br/>

						<Paragraph>
          Millennials--the generation rapidly changing the way we eat, work, exercise, shop, and now, the way we give. Characterized as the generation born between 1980 and 2000, millennials today range from the ages 18 to 38. With millennials now making up the largest portion of the population, it is imperative that nonprofits begin engaging and securing them as donors. But despite making up more than a quarter of the population, millennials account for only 11% of charitable giving.
							<br/><br/>This may come as a surprise, but millennials have really good intentions. Often referred to as the purpose-driven generation, millennials care about doing social good and yearn to make an impact on the world around them. But there’s a problem--according Blackbaud’s Next Generation of American Giving study, only 25% of millennials believe that monetary giving is the best way to make a difference with charitable organizations.
							<br/><br/>So how can you, as a nonprofit, turn well-meaning, “I’m-going-to-save-the-world” millennials into tangible donors? And even better--how can you turn them into recurring donors?
          In this guide, I cover seven approaches that will help your nonprofit both involve and capture the millennial generation.

							<ul>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter1/'}>Chapter 1: 3 ways to get Millennials directly engaged with your organization</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter2/'}>Chapter 2: Generate Social Currency (aka Millennial Candy)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter3/'}>Chapter 3: 4 Methods of Transparency that Millennials Love</Link></li>
								<li>Chapter 4: How To Make Your Social Media Attractive and Effective</li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter5/'}>Chapter 5: Turn the Tables By Getting Millennials To Fundraise For You</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter6/'}>Chapter 6: How to get Millennials to Pull the Trigger and Donate (in 2 minutes)</Link></li>
								<li><Link onClick={this.linking} style={{ display: 'inline' }} href={'/blog/posts/MillennialsChapter7/'}>Chapter 7: Turning One-Time Donors into Lifetime Donors</Link></li>
							</ul>

							<Subtitle fontSize={'25px'} style={{ marginTop:'1em' }} id="chapter4">Chapter 4: How To Make Your Social Media Attractive and Effective</Subtitle>
					Social Media. This is probably what you’ve taken away from every Google search you’ve ever done on millennials. Well, there’s a reason for that--to say they love their social media is an understatement. They can’t (or at least think they can’t) live without it.
							<br/><br/>Not that it’s right, but millennials will often judge the credibility and legitimacy of a brand, company, or organization based on their social media presence. You want to make sure your social media accounts are both active and aesthetically pleasing. There’s a lot that goes into running successful social media accounts (that’s why it’s some people’s entire job), but there are also a few, easy-to-implement tricks to making your social media attractive to the millennial eye.
							<ul><li><strong>Post at least twice a week. </strong>To a Millennial, an active social media account translates to an active organization. You can use awesome social media management tools like <Link style={{ display:'inline' }} href={'https://buffer.com'}>Buffer</Link> to schedule and automate your posts. </li>
								<li><strong>Keep your photos high quality</strong>. Selfies taken on your iPhone just won’t cut it anymore. Don’t have a fancy camera? No worries, sites like <Link style={{ display:'inline' }} href={'https://unsplash.com'}>Unsplash</Link> and <Link style={{ display:'inline' }} href={'https://www.pexels.com'}>Pexels</Link> have awesome high quality stock photos.</li>
								<li><strong>Keep your photos consistent. </strong>Apply the same filter to every photo you post. Choose only bright or dark photos. Decide on a color scheme and stick to it. </li>
								<li><strong>Master the algorithm</strong>. Do you know that algorithms on Twitter, Instagram, and Facebook determine how many people your content gets displayed to? Learn to beat those algorithms by reading these articles: </li>
								<ul>
									<li><Link style={{ display:'inline' }} href={'https://blog.bufferapp.com/instagram-feed-algorithm'}>How the Instagram Algorithm Works in 2018: Everything You Need to Know</Link></li>
									<li><Link style={{ display:'inline' }} href={'https://blog.bufferapp.com/twitter-timeline-algorithm'}>How the Twitter Timeline Works (And 6 Simple Tactics to Increase Your Reach)</Link></li>
									<li><Link style={{ display:'inline' }} href={'https://sproutsocial.com/insights/facebook-algorithm/'}>9 Effective Ways to Outsmart the Facebook Algorithm in 2018</Link></li>
								</ul>
							</ul>


							<hr style={{ margin: '1em 0' }}/>

							<Subtitle fontSize={'30px'}>
							Looking for the full e-book?
							</Subtitle>
							<Paragraph
								fontSize={'21px'}
								marginRight={"2em"}
								fontWeight={'200'}>Fill out this form to receive our free guide <span style={{ fontStyle: 'italic' }}>Millennials To Donors</span></Paragraph>
							<HubspotForm
								id="reactHubspotForm"
								portalId="4829666"
								formId="1c650ab1-5fd3-4250-88c3-269180d71e88"
								loading={<div>{'Loading...'}</div>}
							/>


							<hr style={{ margin: '1em 0' }}/>
							<br/>
							<br/>
              Now I’ll turn it over to you. What do you think of this guide? Is there anything I missed or you want to see covered? Please send me your thoughts at <Link style={{ display: 'inline' }} href="mailto:laura@flourishchange.com">laura@flourishchange.com</Link>.

						</Paragraph>
						<BlogAuthor author="laura"/>
					</Container>

				</Section>
			</Layout>
		)
	}
}

export default IndexPage
